import {
  Avatar,
  Button,
  Dropdown,
  Grid,
  Layout,
} from '@enterprise-ui/canvas-ui-react'
import { useAuth } from '@praxis/component-auth'

const getInitials = (userInfo) =>
  `${userInfo.firstName.charAt(0)}${userInfo.lastName.charAt(0)}`

export const Header = () => {
  const auth = useAuth()
  const { logout, session } = auth
  return (
    <>
      <Layout.Header />
      <Layout.GlobalActions>
        <Grid.Container justify="flex-end">
          <Grid.Item>
            <Dropdown size="dense" location="bottom-right">
              <Button
                aria-label="Click to open dropdown menu"
                iconOnly
                className="praxcss-avatarButton"
                data-testid="headerGlobalActionsButton"
              >
                <Avatar
                  aria-label={`Avatar showing the initials for ${session.userInfo.fullName}`}
                >
                  {getInitials(session.userInfo)}
                </Avatar>
              </Button>
              <Dropdown.Menu>
                <Dropdown.MenuItem
                  data-testid="headerLogoutButton"
                  onClick={logout}
                >
                  Log Out
                </Dropdown.MenuItem>
              </Dropdown.Menu>
            </Dropdown>
          </Grid.Item>
        </Grid.Container>
      </Layout.GlobalActions>
    </>
  )
}
