import { Button, Card, Grid } from '@enterprise-ui/canvas-ui-react'
import logo from '../assets/MVS Logo.svg?url'

const RedirectionPage = (props) => {
  const cardSize = {
    width: '60%',
    position: 'absolute',
    left: '20%',
    top: '20%',
  }

  return (
    <>
      <Card style={cardSize} className="hc-pa-expanded hc-ta-center">
        <Grid.Container>
          {!props.isExternal && (
            <Grid.Item xs={12}>
              <div>
                <img
                  id="paper"
                  value="paper"
                  src={logo}
                  alt="MVS"
                  style={{ cursor: 'pointer' }}
                  className="hc-pb-normal"
                />
              </div>
              <strong className="hc-fs-xl">
                We've Upgraded and Relocated!
              </strong>
              <p className="hc-pt-dense hc-fs-md">
                Experience the all-new and elevated MVS platform by clicking on
                the below button.
              </p>
              <p className="hc-pt-dense hc-fs-md">
                Thank you for joining us on this digital transformation journey!
              </p>
              <br />
              <p className="hc-pt-dense hc-fs-md">
                Kindly note that the old URL{' '}
                <a
                  href={'https://manufacturing.prod.target.com'}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: '#366cd9' }}
                >
                  https://manufacturing.prod.target.com
                </a>{' '}
                can no longer be used and ensure that the new URL{' '}
                <a
                  href={'https://mvs.prod.target.com'}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: '#366cd9' }}
                >
                  https://mvs.prod.target.com
                </a>{' '}
                is bookmarked.
              </p>
              <br />
              <p className="hc-pt-dense hc-fs-md">
                For any questions/comments/feedback, please write to{' '}
                <strong>MVS-feedback@target.com</strong>
              </p>
            </Grid.Item>
          )}

          <Grid.Item
            xs={12}
            className="hc-ta-center hc-pt-expanded hc-mt-expanded"
          >
            <Button
              className="hc-pl-none hc-pr-none"
              style={{
                backgroundColor: '#366cd9',
                color: '#fff',
                width: '260px',
              }}
            >
              <a
                href={'https://mvs.prod.target.com'}
                target="_blank"
                rel="noreferrer"
                style={{ textTransform: 'capitalize', width: '100%' }}
              >
                Launch Now
              </a>
            </Button>
          </Grid.Item>
        </Grid.Container>
      </Card>
    </>
  )
}
export default RedirectionPage
