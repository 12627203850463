import { BrowserRouter as Router } from 'react-router-dom'
import '@enterprise-ui/canvas-ui-css'
import { HelveticaForTarget } from '@praxis/component-font'
import { AuthProvider } from '@praxis/component-auth'
import { EnvProvider } from '@praxis/component-runtime-env'

import './globalStyles/customStyles.scss'
import { Main } from './views/Main'

const App = () => {
  return (
    <EnvProvider
      configPath={
        process.env.NODE_ENV === 'development'
          ? '/config.json'
          : '/app-environment'
      }
    >
      {({ env }) => {
        return (
          <>
            <HelveticaForTarget variants={['n4', 'n5', 'n7']} />
            <Router>
              <AuthProvider
                clientId={env.clientId}
                authorizationUrl={env.authorizationUrl}
                logoutUrl={env.logoutUrl}
              >
                <Main />
              </AuthProvider>
            </Router>
          </>
        )
      }}
    </EnvProvider>
  )
}

export default App
