import { Layout } from '@enterprise-ui/canvas-ui-react'
import { useAuth } from '@praxis/component-auth'

import { LogIn } from './LogIn'
import { MainRouter } from '../globalComponents/MainRouter'
import { Header } from '../globalComponents/Header'

export const Main = () => {
  const auth = useAuth()
  const { isAuthenticated } = auth

  if (isAuthenticated()) {
    return (
      <Layout
        theme="default" // configure theme here
        darkMode="system" // configure dark mode here
        data-testid="mainAuthenticatedLayout"
      >
        <Header />
        <MainRouter />
      </Layout>
    )
  } else {
    return <LogIn />
  }
}
