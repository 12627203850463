import { Routes, Route, useLocation } from 'react-router-dom'
import { ErrorBoundary } from '@praxis/component-logging'

import { ErrorContent } from '../globalComponents/ErrorContent'
import RedirectionPage from '../views/RedirectionPage'

export const MainRouter = () => {
  const location = useLocation()
  return (
    <ErrorBoundary key={location.pathname} FallbackComponent={ErrorContent}>
      <Routes>
        <Route path="*" element={<RedirectionPage />}></Route>
      </Routes>
    </ErrorBoundary>
  )
}
